<template>
  <div class="pad-3">
    <div class="bb-container bb-container--lista">
      <h1 class="header-1">{{ $t("affiliationPolicy.affiliationPolicy") }}</h1>
      <div class="post-entry">
        <p>
          {{ $t("affiliationPolicy.desc1") }}
        </p>
        <p>
          {{ $t("affiliationPolicy.desc2") }}
        </p>
        <h3><strong>{{ $t("affiliationPolicy.affiliatesTitle") }}</strong></h3>
        <p>
          {{ $t("affiliationPolicy.affiliatesText1") }}
        </p>
        <p>
          {{ $t("affiliationPolicy.affiliatesText2") }}
        </p>
        <h3><strong>{{ $t("affiliationPolicy.advertisingTitle") }}</strong></h3>
        <p>
          {{ $t("affiliationPolicy.advertisingText1") }}
        </p>
        <p>
          {{ $t("affiliationPolicy.advertisingText2") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvertisingAndAffiliation",
  metaInfo() {
    return {
      title: this.$t("affiliationPolicy.metaTitle"),
      meta: [
        { name: "description", content: this.$t("affiliationPolicy.metaDescription") },
        { name: "robots", content: "noindex"}
      ]
    };
  },
};
</script>
