<template>
  <div class="page-mapaweb">
    <div class="bb-container">
      <h1 class="page-mapaweb__header">Mapa Web</h1>
    </div>

    <!-- Sección a duplicar FIN ////////////// -->
    <!-- Sección para mostrar las etiquetas del blog -->
    <section class="mw">
      <div class="bb-container">
        <h2 class="mw__h">Categorías de productos</h2>
        <div class="mw__items">
          <div class="mw__item" v-for="(column, columnIndex) in columnsCategories" :key="columnIndex">
            <div class="mw__category" v-for="(cat, catIndex) in column" :key="catIndex">
              <a :href="categoryLink(cat)"
                      @click.prevent="gotoCategory(cat)">
                {{ cat.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Sección a duplicar FIN ////////////// -->
    <!-- Sección para mostrar las etiquetas del blog -->
    <section class="mw">
      <div class="bb-container">
        <h2 class="mw__h">Categorías del blog</h2>
        <div class="mw__items">
          <div class="mw__item" v-for="(column, columnIndex) in columnsBlogTags" :key="columnIndex">
            <div class="mw__category" v-for="blogTag in column" :key="blogTag.id">
              <a :href="blogCategoryLink(blogTag)">
                {{ blogTag.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Sección a duplicar FIN ////////////// -->
    <!-- Sección a duplicar ////////////// -->
    <section class="mw">
      <div class="bb-container">
        <h2 class="mw__h">Términos relacionados</h2>
        <div class="mw__items">
          <div class="mw__item" v-for="(column, columnIndex) in columnsCollections" :key="columnIndex">
            <div class="mw__category" v-for="collection in column" :key="collection.id">
              <a :href="collectionLink(collection)" @click.prevent="gotoCollection(collection)">
                {{ collection.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Sección a duplicar FIN ////////////// -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CategoryLink from "../catalogue/components/mixins/CategoryLink";
import GotoCategory from "../catalogue/components/mixins/GotoCategory";
import GotoCollection from "../catalogue/components/mixins/GotoCollection";
import CollectionLink from "../catalogue/components/mixins/CollectionLink";
import GotoBlogCategory from "../catalogue/components/mixins/GotoBlogCategory";
import BlogCategoryLink from "../catalogue/components/mixins/BlogCategoryLink";

export default {
  name: "MapaWeb",
  mixins: [CategoryLink, GotoCategory, GotoCollection, CollectionLink, GotoBlogCategory, BlogCategoryLink],
  computed: {
    ...mapGetters({
      categories: "categories",
      collections: "collections",
      blogTags: "blogTags",
      collectionsPrefetched: "collectionsPrefetched",
      categoriesPrefetched: "categoriesPrefetched",
      blogTagsPrefetched: "blogTagsPrefetched",
    })
  },
  data() {
    return {
      columnsCollections: [],
      columnsBlogTags: [],
      categoryList: [],
      columnsCategories: [],
    };
  },
  async serverPrefetch() {
    await this.getCategoriesFromServer();
    this.setCategoryList();
    this.columnsCategories = this.chunkArray(this.categoryList, 10);

    await this.getCollectionsFromServer();
    this.columnsCollections = this.chunkArray(this.collections, 10);

    await this.getBlogTagsFromServer();
    this.columnsBlogTags = this.chunkArray(this.blogTags, 10);

  },
  async mounted() {
    if (!this.categoriesPrefetched) {
      await this.getCategories();
    }
    this.setCategoryList();
    this.columnsCategories = this.chunkArray(this.categoryList, 10);

    if (!this.collectionsPrefetched) {
      await this.getCollections();
    }
    this.columnsCollections = this.chunkArray(this.collections, 10);

    if (!this.blogTagsPrefetched) {
      await this.getBlogTags();
    }
    this.columnsBlogTags = this.chunkArray(this.blogTags, 10);

  },
  methods: {
    chunkArray(array, chunkSize) {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    },
    setCategoryList() {
      //create a list of categories (slug and name) from this.categories (3 levels)
      this.categories.forEach((category) => {
        this.categoryList.push({
          slug: category.slug,
          name: category.name,
          id: category.id,
        });
        if (category.subCategory) {
          category.subCategory.forEach((subCategory) => {
            this.categoryList.push({
              slug: subCategory.slug,
              name: subCategory.name,
              id: subCategory.id,
            });
            if (subCategory.subCategory) {
              subCategory.subCategory.forEach((subSubCategory) => {
                this.categoryList.push({
                  slug: subSubCategory.slug,
                  name: subSubCategory.name,
                  id: subSubCategory.id,
                });
              });
            }
          });
        }
      });
    },
    async getCategories(server = false) {
      return await this.$store.dispatch("getCategories", server);
    },
    async getCategoriesFromServer() {
      return await this.getCategories(true);
    },
    async getCollections(server = false) {
      return await this.$store.dispatch("getCollections", server);
    },
    async getCollectionsFromServer() {
      return await this.getCollections(true);
    },
    async getBlogTags(server = false) {
      return await this.$store.dispatch("getBlogTagsData", server);
    },
    async getBlogTagsFromServer() {
      return await this.getBlogTags(true);
    },

  }
};
</script>
