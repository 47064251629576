export default {
  methods: {
    categoryRoute(category) {
      return {
        name: "category",
        params: {
          slug: category.slug,
        },
      };
    },
    gotoCategory(category) {
      this.$router.push(this.categoryRoute(category));
    },
  }
}