export default {
  methods: {
    blogCategoryRoute(blogTag) {
      return {
        name: "BlogCategory",
        params: {
          slug: blogTag.slug,
        },
      };
    },
    gotoBlogCategory(blogTag) {
      this.$router.push(this.blogCategoryRoute(blogTag));
    },
  }
}
