export default {
  methods: {
    collectionLink(collection) {
      if (collection && collection.name && collection.id) {
        const formattedName = collection.name.toLowerCase().replace(/\s+/g, '-');
        return this.$utils.removeDoubleSlashes(process.env.APP_URL + '/seleccion/' + formattedName + '/' + collection.id);
      } else {
        return '#';
      }
    },
  },
}
