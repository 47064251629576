export default {
  methods: {
    collectionRoute(collection) {
      return {
        name: "collection",
        params: {
          slug:  this.$formatUrlhelloBB(collection.name),
          id: collection.id,
        },
      };
    },
    gotoCollection(collection) {
      this.$router.push(this.collectionRoute(collection));
    },
  },

}
